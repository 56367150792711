
import { ElMessage, ElLoading } from 'element-plus'
import sessionCache from '@/utils/cache'

import {
  tokenValidateApi,
} from "@/services/index.js";


export function loginValidate(token){

	return new Promise((resolve,reject) => {
		tokenValidateApi(token).then(res => {
			console.log(res);
			if(res?.success){
				resolve(res)
			}else{
				ElMessage({
					message: '登录失效',
					offset: 200,
				})
				console.log('用户不存在正在清除数据');
				sessionCache.deleteCache("jakaUserId");
				sessionCache.deleteCache("jakaUserName");
				sessionCache.deleteCache("jakaUserAccount");
				sessionCache.deleteCache("jakaUserInfo");

				// location.reload()
				resolve(res)
			}
		})
	})
	
	
}

export function checkLogin() {
  let userInfo = {}
  userInfo = sessionCache.getCache("jakaUserInfo") || null
  return new Promise((resolve,reject) => {
		if(userInfo){
			loginValidate(userInfo.token).then(res => {
				if(res.success) {
						resolve(true)
				} else {
					reject(false)
				}
			})
		} else{
			reject(false)
		}
  })
}